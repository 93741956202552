import React from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
// import { Link } from "react-router-dom";
import "./HomeNav.css";

function HomeNav() {
  return (
    <div className="homeNav">
      <Link to="/" className="menu-home">
        <AiIcons.AiOutlineHome />
      </Link>
    </div>
  );
}

export default HomeNav;
