import React, { useState } from "react";
import Nav from "../../Components/Nav/Nav";
import ReactPlayer from "react-player";
import Footer from "../../Components/Footer/Footer";
import "./Projects.css";
import { ProjectData } from "./ProjectData";
import * as BsIcons from "react-icons/bs";
import HomeNav from "../../Components/HomeNav/HomeNav";

function Projects() {
  const [projectCard, setProjectCard] = useState(0);

  let project = ProjectData;

  function nextProject() {
    if (projectCard !== project.length - 1) {
      setProjectCard(projectCard + 1);
    } else {
      setProjectCard(0);
    }
  }

  function prevProject() {
    if (projectCard === 0) {
      setProjectCard(project.length - 1);
    } else {
      setProjectCard(projectCard - 1);
    }
  }

  return (
    <div>
      <Nav />
      <HomeNav />
      <div className="container" key={project[projectCard]}>
        <button className="nextBtn" onClick={nextProject}>
          <BsIcons.BsChevronCompactRight />
        </button>
        <button className="prevBtn" onClick={prevProject}>
          <BsIcons.BsChevronCompactLeft />
        </button>
        <div className="videoContainer">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={project[projectCard].video}
              width="100%"
              height="100%"
            />
          </div>
          <h1 className="projectTitle">{project[projectCard].title}</h1>
          <div className="techContainer">
            <div className="techStackContainer">
              <p className="techStack"> {project[projectCard].tech} </p>
            </div>
          </div>
          <p className="projectDescription">
            {project[projectCard].description1}
          </p>
          <p className="projectDescription">
            {project[projectCard].description2}
          </p>
          <p className="projectDescription">
            {project[projectCard].description3}
          </p>
          <div className="btnContainer">
            <a
              className="codeBtn"
              href={project[projectCard].github}
              target="_blank"
              rel="noreferrer"
            >
              Checkout the Code
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Projects;
