import React, { useRef } from "react";
import Nav from "../../Components/Nav/Nav";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ContactMe.css";
import Footer from "../../Components/Footer/Footer";
import HomeNav from "../../Components/HomeNav/HomeNav";

function ContactMe() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_uo3bkeo",
        "template_ebpgood",
        form.current,
        "user_qzuqsQM4gYXMBdyMy1IiI"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Your message has been sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div>
      <Nav />
      <HomeNav />
      <div className="pageContainer">
        <div className="headerContainer">
          <h4 className="contactHeader">Contact Me</h4>
        </div>
        <div className="inputContainer">
          <form className="inputForm" onSubmit={sendEmail} ref={form}>
            <input
              placeholder="Name"
              className="inputField"
              name="name"
            ></input>
            <input
              placeholder="Email"
              className="inputField"
              name="email"
            ></input>
            <input
              placeholder="Phone Number"
              className="inputField"
              name="phone"
            ></input>
            <textarea
              placeholder="Message"
              className="inputField"
              id="message"
              type="text-area"
              name="message"
            ></textarea>
            <button className="submitContactBtn" type="submit">
              submit
            </button>
          </form>
        </div>
        <div className="itemContainer"></div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactMe;
