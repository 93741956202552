import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./Pages/Landing/Landing";
import AboutMe from "./Pages/About/AboutMe";
import Technologies from "./Pages/Technologies/Technologies";
import Projects from "./Pages/Projects/Projects";
import ContactMe from "./Pages/Contact/ContactMe";
import { toast } from "react-toastify";
import Particles from "react-particles-js";
import "./App.css";

toast.configure();

function App() {
  return (
    <div className="App">
      <Particles
        className="particles"
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 2,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/AboutMe" component={AboutMe} />
          <Route exact path="/Technologies" component={Technologies} />
          <Route exact path="/Projects" component={Projects} />
          <Route exact path="/ContactMe" component={ContactMe} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
