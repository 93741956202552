import React from "react";
import Nav from "../../Components/Nav/Nav";
import javascript from "../../Assets/javascript.png";
import html from "../../Assets/html.png";
import css from "../../Assets/css.png";
import postgresql from "../../Assets/postgresql.png";
import node from "../../Assets/node-js.png";
import git from "../../Assets/git.png";
import react from "../../Assets/react.png";
import express from "../../Assets/express.png";
import Footer from "../../Components/Footer/Footer";
import "./technologies.css";
import HomeNav from "../../Components/HomeNav/HomeNav";

//test

function Technologies() {
  return (
    <div>
      <Nav />
      <HomeNav />
      <div className="tPageContainer">
        <div className="techContainer">
          <div className="iconContainer">
            <div className="icon">
              <img src={javascript} alt="JavaScript"></img>
              <br></br>Javascript
            </div>
            <div className="icon">
              <img src={react} alt="react.js"></img>
              <br></br>React.js
            </div>
            <div className="icon">
              <img src={html} alt="HTML"></img>
              <br></br>HTML5
            </div>
            <div className="icon">
              <img src={css} alt="CSS"></img>
              <br></br>CSS3
            </div>
            <div className="icon">
              <img src={postgresql} alt="postgresql"></img>
              <br></br>PostGreSQL
            </div>
            <div className="icon">
              <img src={node} alt="node.js"></img>
              <br></br>Node.js
            </div>
            <div className="icon">
              <img src={git} alt="Git"></img>
              <br></br>Git
            </div>

            <div className="icon">
              <img src={express} alt="express.js"></img>
              <br></br>Express.js
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Technologies;
