import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Nav from "../../Components/Nav/Nav";
import HomeNav from "../../Components/HomeNav/HomeNav";
import Typed from "react-typed";
import "./Landing.css";

export default function Landing() {
  return (
    <div className="homeContainer">
      <Nav />
      <HomeNav />
      <div className="typeBox">
        <div className="staticText">
          <p className="subText">Hello, my name is</p>
          <p className="nameHeader">Michael Roseweir,</p>
          <p className="subText">and I am a</p>
          <Typed
            className="typedText"
            strings={["Collaborator.", "Problem Solver.", "Web Developer."]}
            typeSpeed={100}
            showCursor={false}
            fadeOut={true}
          />
          <br></br>
          <div className="home-btns">
            <Link to="/AboutMe" className="aboutBtn">
              About Me
            </Link>
            <Link to="/Projects" className="aboutBtn">
              Projects
            </Link>
            <Link to="/ContactMe" className="aboutBtn">
              Contact
            </Link>
            <Link
              to={{
                pathname:
                  "https://docs.google.com/document/d/1jrs15Emv76JzhAsjB7-GtfrnUXemwLWwlmaY14Tdzkk/edit?usp=sharing",
              }}
              target="_blank"
              className="aboutBtn"
            >
              Resume
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
