import React, { useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import { Link } from "react-router-dom";
import { NavData } from "./NavData";
import "./Nav.css";
import { IconContext } from "react-icons";

function Nav() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <CgIcons.CgMenuGridO onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars-close">
                <AiIcons.AiOutlineArrowLeft />
              </Link>
            </li>
            {NavData.map((item, index) => {
              return (
                <li key={index} className={item.className}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <li className="nav-text">
              <Link
                to={{
                  pathname:
                    "https://docs.google.com/document/d/1jrs15Emv76JzhAsjB7-GtfrnUXemwLWwlmaY14Tdzkk/edit?usp=sharing",
                }}
                target="_blank"
              >
                <AiIcons.AiOutlineFile />
                <span>Resume</span>
              </Link>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Nav;
