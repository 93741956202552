import React from "react";
import * as AiIcons from "react-icons/ai";

export const NavData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiOutlineHome />,
    className: "nav-text",
  },
  {
    title: "Projects",
    path: "/Projects",
    icon: <AiIcons.AiOutlineFundProjectionScreen />,
    className: "nav-text",
  },
  {
    title: "Technologies",
    path: "/Technologies",
    icon: <AiIcons.AiOutlineCode />,
    className: "nav-text",
  },
  {
    title: "About Me",
    path: "/AboutMe",
    icon: <AiIcons.AiOutlinePushpin />,
    className: "nav-text",
  },
  {
    title: "Contact",
    path: "/ContactMe",
    icon: <AiIcons.AiOutlinePhone />,
    className: "nav-text",
  },
];
