import React from "react";
import Footer from "../../Components/Footer/Footer";
import Nav from "../../Components/Nav/Nav";
import ProfilePicture from "../../Assets/Profile.jpeg";
import "./AboutMe.css";
import HomeNav from "../../Components/HomeNav/HomeNav";

function AboutMe() {
  return (
    <div>
      <Nav />
      <HomeNav />
      <h1 className="AboutHeader">Michael Roseweir</h1>
      <div className="infoContainer">
        <div className="photoContainer">
          <img
            src={ProfilePicture}
            alt="Michael Roseweir"
            className="profilePhoto"
          />
        </div>
        <div className="bio">
          <p className="bioText">
            Hello! My name is Michael Roseweir. I was born in Durban, South
            Africa and will be forever grateful to have grown up in Southern
            California. I currently reside in Dallas, TX. I am open to
            relocation (with plans on moving to Denver, CO in the coming year).
            I have a plethora of experience in Sales, Management, as well as
            working in a team in several different work environments. I am
            driven by the individuals around me, finding happiness in watching
            everyone succeed. <br></br> Currently, I am seeking opportunities to
            join a team as a Web Developer. My primary stack is PERN, but
            working to familiarize myself with the MEAN stack as well. <br></br>{" "}
            Please do not hesitate to reach out to me as I am constantly looking
            to grow my network and collaborate with new people on exciting
            projects.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutMe;
