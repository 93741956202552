export const ProjectData = [
  {
    title: "Bottoms Up",
    video: "https://vimeo.com/611938452",
    description1:
      "Bottoms Up is a PERN Stack app allowing users to search and save new cocktails they can make at home.",
    description2:
      "Users are able to create an account using just a name, email and password. Once an account is created they are able to save drinks to their favorites. Within their favorites the user is able to save comments about recipe alterations or their thoughts for each specific drink.",
    description3:
      "BCrypt is being utilized to ensure the passwords are being hashed before they are sent to the database. JWT has been used for authentication.",
    tech: "React | Express | Node | PostGreSQL | BCrypt | JWT | Axios | Toastify | HTML5 | CSS3",
    github: "https://github.com/mroseweir/bottoms-up",
  },
  {
    title: "PP-Points Tracker",
    video: "https://vimeo.com/625762295",
    description1:
      "Pair Programming is highly encouraged and rewarded at DevMountain. The faculty tracks Pair Programming points for each student on a daily basis. Quickly we realized that there was no way for us to see our points without reaching out to the faculty.",
    description2:
      "Myself and 2 other students in the Cohort built the PP Points Tracker app that allows the faculty to add students to a directory, adjust their points, and notate whether or not that have recieved their rewards.",
    description3:
      "The app also includes a student facing directory which allows the students to search for their cohort, and monitor their points without having to reach out to the instructor.",
    tech: "React | Express | Node | FireBase | Axios | HTML5 | CSS3",
    github: "https://github.com/LoganBready/pp-points",
  },
];
