import React from "react";
import * as AiIcons from "react-icons/ai";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="logoContainer">
        <a
          href={"https://www.linkedin.com/in/roseweir/"}
          target="_blank"
          rel="noreferrer"
          className="logos"
        >
          <AiIcons.AiOutlineLinkedin />
        </a>
        <p className="logos"> </p>
        <a
          href={"https://github.com/mroseweir"}
          target="_blank"
          rel="noreferrer"
          className="logos"
        >
          <AiIcons.AiFillGithub />
        </a>
      </div>
    </div>
  );
}

export default Footer;
